import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IAppState } from '../store/states/app.state';
import { Store } from '@ngrx/store';
import { Fields } from '../constants/fields';
import { FieldsGroups } from '../constants/field-groups';
import { IFieldItem } from 'src/entities/field.interface';
import { isUndefined } from '../utils/general.utils';

interface ICache {
	[key: string]: BehaviorSubject<any>;
}
// type serializable = object | Object;

@Injectable()
export class SessionStorageService {
	FieldsGroups = FieldsGroups;
	Fields = Fields;
	private cache: ICache;
	store$: Observable<IAppState>;

	constructor(private store: Store) {
		this.cache = Object.create(null);
		this.store$ = this.store.select((state: IAppState) => state);
	}

	initStoreSub(): void {
		this.store$.subscribe((state: IAppState) => {
			// this.setItem('store', state);
			if (state.quoteData && !sessionStorage.getItem('overviewGroupingFields')) {
				//this.initFieldsGroups(state.quoteData);
			}
		});
	}

	initFieldsGroups(quoteData) {
		let overviewGroupingFields = [];
		Object.keys(FieldsGroups).forEach((group) => {
			let result = true;

			FieldsGroups[group].forEach((element: IFieldItem) => {
				const groupException = element.groupException;
				let haveGroupException = false;
				if (groupException) {
					const field = Fields[groupException.fieldName];
					const fieldValue = quoteData[field.name];

					haveGroupException = isUndefined(groupException.validValues.find((value) => value == fieldValue));
				}
				if ((isUndefined(quoteData[element.name]) || quoteData[element.name] == null) && !haveGroupException) {
					result = false;
				}
			});
			if (result) {
				FieldsGroups[group].forEach((element) => {
					overviewGroupingFields.push(element.name);
				});
			}
		});
		sessionStorage.setItem('overviewGroupingFields', JSON.stringify(overviewGroupingFields));
	}

	setItem(key: string, value): BehaviorSubject<any> {
		sessionStorage.setItem(key, JSON.stringify(value));

		if (this.cache[key]) {
			this.cache[key].next(value);
			return this.cache[key];
		}

		return (this.cache[key] = new BehaviorSubject(value));
	}

	getItem(key: string): BehaviorSubject<any> {
		if (this.cache[key]) return this.cache[key];
		else return (this.cache[key] = new BehaviorSubject(JSON.parse(sessionStorage.getItem(key))));
	}

	removeItem(key: string) {
		sessionStorage.removeItem(key);
		if (this.cache[key]) this.cache[key].next(undefined);
	}
}
