import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SetQuoteData, UpdateQuoteData, UpdateQuoteDataFinished } from '../actions/quote-data.actions';
import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { UpdateInterviewMetadata } from '../actions/interview-metadata.actions';
import { IMappedSessionResponse } from '../../../entities/session-response.interface';

@Injectable()
export class QuoteDataEffect {
	constructor(private actions$: Actions) { }

	setQuoteData$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SetQuoteData),
			switchMap((res: IMappedSessionResponse) => [
				UpdateQuoteData({ data: res.quoteData }),
				UpdateInterviewMetadata({ data: res.properties }),
				UpdateQuoteDataFinished(),
			])
		)
	);
}
