import { CssPropertiesEnum } from '../../models/css-properties.enum';
import { GeneralPropsEnum } from '../../models/general-props.enum';
import { StepsEnum } from '../../enums/steps.enum';
import { FontsEnum } from '../../models/fonts.enum';
import { ThemeModel } from '../../models/theme.model';
import { AdditionalClassesEnum } from '../../models/additional-classes.enum';
import { SectionTypesEnum } from '../../models/section-types.enum';

export const initialActiveTheme: ThemeModel = {
	[GeneralPropsEnum.name]: 'local',
	[GeneralPropsEnum.marketingName]: null,
	[GeneralPropsEnum.agentPhoneNumber]: '1-000-000-0000',
	[GeneralPropsEnum.properties]: {
		[CssPropertiesEnum.fontFamily]: 'Roboto, sans-serif',
		[CssPropertiesEnum.background]: '#f2f2f5',
		[CssPropertiesEnum.primaryColor]: '#170f4f',
		[CssPropertiesEnum.secondaryColor]: '#00bfcc',
		[CssPropertiesEnum.loaderColor]: '#00bfcc',
		[CssPropertiesEnum.loaderTextColor]: '#707070',
		[CssPropertiesEnum.progressBarColor]: '#00bfcc',
		[CssPropertiesEnum.progressBarBackground]: '#f2f2f5',
		[CssPropertiesEnum.progressBarHeight]: '10',
		[CssPropertiesEnum.headerHeight]: '90',
		[CssPropertiesEnum.headerBoxShadow]: '0 1px 1px #00000005',
		[CssPropertiesEnum.headerAgentColor]: '#170f4f',
		[CssPropertiesEnum.headerBackground]: '#ffffff',
		[CssPropertiesEnum.headerButtonBackground]: '#ffffff',
		[CssPropertiesEnum.headerButtonColor]: '#170f4f',
		[CssPropertiesEnum.headerButtonBorder]: '1px solid #e8e8e8',
		[CssPropertiesEnum.footerCopyright]: 'rgba(255, 255, 255, 0.32)',
		[CssPropertiesEnum.errorColor]: '#ed1c24',
		[CssPropertiesEnum.logoHeight]: '40',
		[CssPropertiesEnum.logoWidth]: '94',
		[CssPropertiesEnum.mainTitleColor]: '#170f4f',
		[CssPropertiesEnum.mainTitleColor2]: '#767676',
		[CssPropertiesEnum.mainTitleWeight]: '500',
		[CssPropertiesEnum.mainTitleFont]: 'Roboto, sans-serif',
		[CssPropertiesEnum.buttonBorderRadius]: '0',
		[CssPropertiesEnum.buttonIconVisibility]: 'block',
		[CssPropertiesEnum.buttonFontSize]: '20',
		[CssPropertiesEnum.buttonHeight]: '58',
		[CssPropertiesEnum.buttonWidth]: '185',
		[CssPropertiesEnum.buttonBackground]: '#170f4f',
		[CssPropertiesEnum.buttonBackgroundHover]: '#170f4f',
		[CssPropertiesEnum.buttonColor]: '#ffffff',
		[CssPropertiesEnum.buttonColorHover]: '#ffffff',
		[CssPropertiesEnum.buttonIconColor]: '#00bfcc',
		[CssPropertiesEnum.buttonIconColorHover]: '#00bfcc',
		[CssPropertiesEnum.buttonBorderColor]: '#170f4f',
		[CssPropertiesEnum.button2Background]: 'transparent',
		[CssPropertiesEnum.button2BackgroundHover]: '#170f4f',
		[CssPropertiesEnum.button2Color]: '#170f4f',
		[CssPropertiesEnum.button2ColorHover]: '#ffffff',
		[CssPropertiesEnum.button2IconColor]: '#00bfcc',
		[CssPropertiesEnum.button2IconColorHover]: '#00bfcc',
		[CssPropertiesEnum.button2BorderColor]: '#170f4f',
		[CssPropertiesEnum.linkColor]: '#00bfcc',
		[CssPropertiesEnum.inputBackground]: '#f2f2f5',
		[CssPropertiesEnum.inputBackgroundFocus]: '#f2f2f5',
		[CssPropertiesEnum.inputBackgroundSelect]: '#ccf1f4',
		[CssPropertiesEnum.inputShadowFocus]: 'none',
		[CssPropertiesEnum.inputColor]: '#170f4f',
		[CssPropertiesEnum.inputColorSelect]: '#00bfcc',
		[CssPropertiesEnum.inputColorDisabled]: '#cccccc',
		[CssPropertiesEnum.inputIconColor]: '#6f6a8e',
		[CssPropertiesEnum.inputSecondaryColor]: '#00bfcc',
		[CssPropertiesEnum.inputSecondaryColorHover]: '#ccf1f4',
		[CssPropertiesEnum.inputBorderRadius]: '0',
		[CssPropertiesEnum.inputBorderColor]: 'transparent',
		[CssPropertiesEnum.inputBorderWidth]: '0',
		[CssPropertiesEnum.inputPlaceholder]: '#767676',
		[CssPropertiesEnum.inputLabel]: '#767676',
		[CssPropertiesEnum.selectOptionColor]: '#2d2d2d',
		[CssPropertiesEnum.selectOptionColorSelected]: '#2d2d2d',
		[CssPropertiesEnum.selectCheckColor]: '#00bfcc',
		[CssPropertiesEnum.selectOptionBackground]: '#ccf1f4',
		[CssPropertiesEnum.selectOptionBackgroundHover]: '#f5faff',
		[CssPropertiesEnum.radioSelectColor]: '#00bfcc',
		[CssPropertiesEnum.radioImageBorderWidth]: '2',
		[CssPropertiesEnum.radioImageBorderColor]: '#f2f2f2',
		[CssPropertiesEnum.radioImageBorderRadius]: '0',
		[CssPropertiesEnum.radioImageBorderColorChecked]: '#00bfcc',
		[CssPropertiesEnum.questionShadow]: '0 2px 8px #0000004d',
		[CssPropertiesEnum.questionPadding]: '20',
		[CssPropertiesEnum.questionRadius]: '0',
		[CssPropertiesEnum.popupRadius]: '0',
		[CssPropertiesEnum.popupBg]: '#ffffff',
		[CssPropertiesEnum.popupColor]: '#000000',
		[CssPropertiesEnum.popupHeaderBg]: '#00bfcc',
		[CssPropertiesEnum.popupHeaderColor]: '#ffffff',
		[CssPropertiesEnum.popupCloseBg]: '#ffffff',
		[CssPropertiesEnum.popupCloseColor]: '#453f72',
	},
	[GeneralPropsEnum.additionalClasses]: {
		[AdditionalClassesEnum.startImageView]: 'start-page-image-default',
		[AdditionalClassesEnum.mainLogoVisibility]: 'main-logo-all-pages',
	},
	[GeneralPropsEnum.fonts]: {
		[FontsEnum.general]: 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap',
		[FontsEnum.title]: null,
	},
	[GeneralPropsEnum.texts]: {
		[StepsEnum.RESULT_LOADER]: {
			title: 'Getting the best quotes for you...',
			subTitle: 'Comparing quotes across the leading insurance companies',
		},
		[StepsEnum.RESULT]: {
			title: '<%first_name%>, here are the best quotes for you',
			subTitle: '',
			oemTitle: 'Choose Your Parts Coverage Options',
			oemSubtitle: 'For other carriers call us at <%agent_number%>',
			verifiedTitle: 'Verified carriers (OEM Parts)',
			verifiedTtTitle: 'Recommended',
			alternativeTitle: 'Alternative (Aftermarket Parts)',
			alternativeTtTitle: 'Important to know',
			verifiedPpTitle: 'OEM Parts Selection',
			callAgentTitle: 'Call an agent to finalize your quote',
			talkToAgent: 'Click here to answer a few additional questions specific to your coverage needs.',
			verifiedPpText:
				'Protect the value of your BMW, <b>Choose</b> BMW’s OEM parts replacement coverage to confirm repairs with parts designed for your BMW, ensuring top performance, perfect fit, and safety.',
			alternativePpTitle: 'Alternative carriers',
			alternativePpText:
				"<h4>Non-OEM Parts Acknowledgment</h4> By not selecting OEM parts coverage, you acknowledge the potential use of aftermarket parts in repairs, which may differ in quality and compatibility from BMW's OEM parts. Your understanding and acceptance of this is required.",
			nonOemPpTitle: 'Non-OEM Parts Selection',
			loadAltCarriersButton: 'Load alternative carriers',
			nonOemPpText:
				'If you decline OEM coverage, (potential extra charges can occur at lease-end aligned with lease contract terms and conditions) you acknowledge that parts other than new BMW replacement parts, differing in quality and compatibility, may be used for your repairs. New BMW OEM replacement parts are required by BMW leases and repair instructions',
			allCovOnlineBtn: 'Select',
			allCovOfflineBtn: 'Continue',
			coveragesMatchNotFound:
				'Sorry, we couldn’t find a match to the coverages you selected but check out these alternatives you might like!',
		},
		contactAgentPopup: {
			title: 'Contact bolt agency to finalize your booking',
		},
		allCoveragesPopup: {
			title: null,
		},
		bundleBox: {
			title: 'Did you know?',
			subTitle: 'You can get a good offer by bundling <%base_lob%> and <%bundle_lob%> insurance',
			button: 'Bundle with <%bundle_lob%>',
		},
		tspa: {
			tcpaOn: true,
			tcpaText: 'I agree to receive email and phone communications from bolt Insurance.',
			legalConsentOn: false,
			legalConsent: '',
			legalText:
				'To provide accurate quotes, the insurance companies we represent may order consumer reports, including credit score, loss history, and driver history. By clicking "Continue," you agree to share the information you entered with insurance companies. You also agree to bolt\'s <a href="https://consumer.boltinsurance.com/Themes/BOLT/Content/Documents/BOLT%20Terms%20and%20Conditions.pdf\'" target="_blank" > Terms and Conditions </a> and <a href="https://consumer.boltinsurance.com/Themes/BOLT/Content/Documents/BOLT%20Privacy%20Policy.pdf" target="_blank"> Privacy Statement </a>.',
		},
		header: {
			FindMyQuoteText: 'Find my quote',
		},
		[StepsEnum.LINK_EXPIRED]: {
			title: 'This link has expired',
			subTitle:
				"We're sorry, but the link you used to start your insurance purchase is no longer valid. To continue, please generate a new link.",
		},
		[StepsEnum.LINK_EXPIRED_AGENT]: {
			title: 'This link has expired.<br/>Contact an agent to proceed',
			subTitle:
				"We're sorry, but the link you used to start your insurance purchase is no longer valid. Please contact an agent to continue with your insurance purchase.",
		},
		[StepsEnum.LINK_SENT]: {
			title: 'A new link has been sent.<br>Check your inbox.',
			subTitle: '',
		},
		[StepsEnum.SUCCESS_PAGE]: {
			title: 'Thank you!',
			subTitle: 'Your payment was successful. </br> The policy will be emailed to you shortly.',
		},
		[StepsEnum.SUCCESS_PAGE_ESCROW]: {
			title: 'Thank you!',
			subTitle:
				'Your request was sent. The agent will set up the escrow account. You will get a confirmation with the policy info in your email once approved. If wanting to bind today, for more immediate assistance, please contact our agency.',
		},
		[StepsEnum.END_SESSION]: {
			title: 'We ended your session to keep your info safe',
			subTitle: 'Please log back into the system to continue.',
		},
		[StepsEnum.TECHNICAL_ERROR]: {
			title: "Sorry, we're experiencing technical difficulties",
			backButtonOn: true,
			backButtonText: 'Go Home',
			subTitle: '',
		},
		[StepsEnum.PAYMENT_FAILED]: {
			title: 'Oops! Something went wrong',
			subTitle: 'We couldn’t complete your payment. <br />Please try again',
		},
		[StepsEnum.NO_RESULTS]: {
			title: "We're sorry...",
			subTitle:
				'We could’t find any eligible carriers for your selection. <br /> Please call us at <%agent_number%> and one of our agents will help you.',
		},
		[StepsEnum.STATE_NOT_ALLOWED]: {
			title: "Sorry, we're not available in your state yet!",
			subTitle: 'Please call <%agent_number%> to speak to a licensed agent to continue your quote.',
		},
		[StepsEnum.CARRIER_KICKOUT]: {
			title: `We couldn’t find a quote for you`,
			subTitle: `Call <%agent_number%> to speak with a licensed agent.`,
		},
		[StepsEnum.PAYMENT]: {
			title: `Please select your payment plan`,
			subTitle: ``,
		},
		[StepsEnum.AGENT_LOCKED]: {
			callAgentButtonText: '<%agent_number%>',
		},
		paymentEscrow: {
			title: 'How would you like to pay?',
			subTitle:
				"If the suggested payment method doesn't suit you, call us at <%agent_number%> for personalized assistance in completing your policy purchase over the phone",
			whatDoYouNeedToKnow:
				'Please note that once this application is submitted to <%selectedCarrier%>, coverage is effective <%EffectiveDate%>. After receiving your approval to submit, you will receive an email from the insurance company requesting your electronic signature of the application. It will be forwarded to our service department, and they will send proof of your new <%lobSelection%> Policy to your mortgage company along with an invoice to collect the insurance premium of <%totalPrice%> from your escrow. You will also receive an email requesting your electronic signature authorizing us to initiate the cancellation of your prior policy on your behalf for this effective date. Any discounts or credits on this policy such as alarm systems, hail resistant roofs, or hurricane shutters will require documentation and verification by the insurance company. If documentation is not received or verification differs from what was reported on your application today, a change in premium may occur. <carrier name display name> may complete an exterior inspection of the property typically within 90 days to verify construction details, replacement cost, occupancy and the presence of any risk factors such as current damage to your home, poor maintenance, unfenced pools, trampolines, animals, or businesses on the premises. <br /><br /> Any refund from your prior policy will be mailed to you once the cancellation has been processed. Make sure you follow up with your mortgage company’s escrow department to have it reapplied to your account and request an escrow analysis to update your account. <br/><br /> If for any reason you decide to cancel this application after it has been submitted and bound for coverage, a signed request to cancel will be required regardless of the policy effective date.',
			whatDoYouNeedToKnowTitle: 'What do you need to know?',
			mortgageBillPayConsent:
				'I give my permission to submit this <%lobSelection%> insurance application to <%selectedCarrier%>, and bind this policy effective <%EffectiveDate%>',
			isMortgageBillPayCheckboxOn: true,
			button: 'Continue',
			legalText: null,
		},
		[StepsEnum.POLICY_DATEPICKER]: {
			title: `Select your policy’s start date`,
			subTitle: ``,
		},
		[StepsEnum.HOUSE_DETAILS]: {
			title: `Tell us more about the house`,
			subTitle: `We need the following information to get you the best quote`,
			titleWithInfo: `Are these details correct?`,
			subTitleWithInfo: `Based on the info you've provided, here are details <br />we have for the house. Please confirm them:`,
		},
		summary: {
			title: `OK! Let's finalize your order`,
			callToAgentPageTitle: `OK! Let's finalize your order`,
			callToAgentPageSubtitle: ``,
			subTitle: ``,
			button: 'Continue to payment',
			buttonEscrow: 'Approve payment',
		},
		quoteConfirmation: {
			title: `Here are your final policy details.`,
			covMainTitle: `What’s inside?`,
			button: `Continue`,
			subTitle: ``,
		},
		primaryDriver: {
			title: "Let's start with you.",
			subTitle:
				'This information will help us designate you as the policyholder and find any additional drivers in your household.',
		},
		primaryVehicle: {
			title: 'Enter your vehicle information',
			subTitle: '',
			titleWithInfo: 'Which cars would you <br> like to insure?',
			subTitleWithInfo: 'Add the cars you would like to insure.',
		},
		drivers: {
			title: 'Who are the other drivers?',
			subTitle:
				"All drivers in the household must be included to ensure you won't be liable for the damages if they get into a car accident.",
			titleWithInfo: 'We found some additional drivers',
			subTitleWithInfo:
				"Based on the address you provided, all drivers in your household must be included to ensure you won't be liable for the damages if they get into a car accident.",
		},
		driverHistory: {
			title: 'Enter your driving history',
			subTitle:
				'How many accidents, losses or violations have you had in the last 5 years? Help us understand your vehicle’s history',
		},
		coverages: {
			title: 'Select your preferred coverage',
			subTitle: '',
			bilTitle: 'Bodily injury liability',
			bilSubTitle:
				'Bodily injury liability coverage covers you in the event you cause an accident that involves injuries or deaths of others.',
			cacTitle: 'Comprehensive and Collision Coverage',
			cacSubTitle:
				'<b>Comprehensive coverage</b> covers damages to your vehicle caused by non-collision events outside of your control. These include weather, theft, vandalism, animals, falling objects, or fire. <br /> <b>Collision coverage</b> covers damages in the event that you hit another vehicle, another vehicle hits you, or if your car rolls over.',
		},
		firstSection: {
			title: 'Get bolt <b>insurance</b> today.',
			subTitle: '',
			button: 'Continue',
			legalText: '',
			mainLegalTextVisible: false,
		},
		anchorCarrier: {
			title: '<%FirstName%>, your anchor carrier insurance quote is ready',
			subTitle: '',
			button: 'Bridge button',
			callAgentTitle: 'Call to agent <%agent_number_link%>',
			legalText: 'Please check more coverages by clicking ‘Bridge button’',
			covMainTitle: `What’s inside?`,
		},
		loaderFQReports: {
			title: 'Just a moment...',
			subTitle: 'Please wait while the carrier retrieves your claims history.',
		},
		safetyAlarms: {
			title: 'Which of the following are <br>present on the property?',
			subTitle: 'You can select more than one option',
		},
	},
	[GeneralPropsEnum.landingPage]: [
		{
			[GeneralPropsEnum.name]: 'firstSection',
			[GeneralPropsEnum.title]: 'First Section',
			[GeneralPropsEnum.enabled]: true,
			[GeneralPropsEnum.type]: SectionTypesEnum.sectionMain,
			[GeneralPropsEnum.additionalClasses]: {
				[AdditionalClassesEnum.startImageView]: 'start-page-image-default',
				[AdditionalClassesEnum.legacyTextView]: 'legacy-text-default',
			},
			[GeneralPropsEnum.properties]: {
				[CssPropertiesEnum.startPageBg]: '#f2f2f5',
				[CssPropertiesEnum.startPageFormBg]: 'none',
				[CssPropertiesEnum.startPageFormRadius]: '0',
				[CssPropertiesEnum.startPageFormLegal]: '#767676',
				[CssPropertiesEnum.startPageTitleColor]: '#170f4f',
				[CssPropertiesEnum.startPageTitleWeight]: '400',
				[CssPropertiesEnum.startPageImageVisibility]: 'block',
				[CssPropertiesEnum.startInputsShadow]: '0 1px 3px #00000029',
				[CssPropertiesEnum.startInputsBg]: '#ffffff',
				[CssPropertiesEnum.startInputsColor]: '#170f4f',
			},
			[GeneralPropsEnum.images]: {
				footerImage: 'footerImage',
			},
		},
	],
	[GeneralPropsEnum.images]: {},
};
