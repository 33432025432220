import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { ProgressMeterActions, RoutingActions } from '../actions';
import { first, tap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { RoutingService } from 'src/app/services/routing.service';
import { ActionApiService } from '../../services/action-api.service';
import { Store } from '@ngrx/store';
import { UpdateQuoteData } from '../actions/quote-data.actions';
import { mapKeyValueArrayToObj } from 'src/app/utils/general.utils';
import { StepsEnum } from 'src/app/enums/steps.enum';
import { QuoteDataService } from 'src/app/services/quote-data.service';
import { IServerRes } from 'src/entities/payload.interface';
import { Fields } from 'src/app/constants/fields';
import { SourcesEnum } from 'src/app/enums/sources.enum';
import { ProgressMeterService } from 'src/app/services/progress-meter.service';

@Injectable()
export class RoutingEffect {
	SourcesEnum = SourcesEnum;
	// TODO: use this to navigate
	navigateToNextPage$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(RoutingActions.NavigateToNextPage),
				tap((serverRes) => {
					combineLatest([this.quoteDataService.serverQuoteErrors, this.quoteDataService.hasErrorsFromServerForThisPage])
						.pipe(first())
						.subscribe(([serverQuoteErrors, hasErrorsFromServerForThisPage]) => {
							if (hasErrorsFromServerForThisPage) {
								// If hasErrorsFromServerForThisPage they will be handled in BasePageComponent
								// or in handleContinue method of particular page component. No redirect needed.
								return;
							} else if (serverQuoteErrors.ErrorCode || serverQuoteErrors.error?.ErrorCode) {
								this.routingService.navigateToErrorPage(StepsEnum.TECHNICAL_ERROR);
							} else {
								// navigate to next route if everything is ok
								// in come cases, e.g. 'carrier-kickout' need to navigate to serverRes.route instead of serverRes.route.name
								this.progressMeterService.goToNextPage();
							}
						});
				})
			),
		{ dispatch: false }
	);

	navigateToErrorPage$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(RoutingActions.NavigateToErrorPage),
				tap((action) => this.routingService.navigateToRoute(`error/${action.errorType}`))
			),
		{ dispatch: false }
	);

	constructor(
		private actions$: Actions,
		private actionService: ActionApiService,
		private store: Store,
		private routingService: RoutingService,
		private quoteDataService: QuoteDataService,
		private progressMeterService: ProgressMeterService
	) { }

	// generateBundleUrl$ = createEffect(
	// 	() =>
	// 		this.actions$.pipe(
	// 			ofType(RoutingActions.GenerateBundleUrl),
	// 			tap((action) => {
	// 				this.actionService
	// 					.post({
	// 						action: 'GenerateBundleUrl',
	// 					})
	// 					.subscribe((res: IServerRes) => {
	// 						if (res && res.redirectedUrl) {
	// 							res.redirectedUrl += `&fromPage=${this.routingService.currentRoute}`;
	// 							this.routingService.navigateToExternal(res.redirectedUrl);
	// 						}
	// 					});
	// 			})
	// 		),
	// 	{ dispatch: false }
	// );

	redirectedUrl(serverRes: IServerRes) {
		if (serverRes.redirectedUrl) {
			this.routingService.navigateToExternal(serverRes.redirectedUrl);
			return;
		}
		if (serverRes.irrelevantDefaultsRemoved) {
			this.store.dispatch(
				UpdateQuoteData({
					data: mapKeyValueArrayToObj([
						{ key: Fields.PLHeatingUpdate.name, value: null },
						{ key: Fields.HeatingUpdateYear.name, value: null },
						{ key: Fields.PLPlumbingUpdated.name, value: null },
						{ key: Fields.PlumbingUpdatedYear.name, value: null },
						{ key: Fields.PLElectircalUpdated.name, value: null },
						{ key: Fields.ElectircalUpdatedYear.name, value: null },
					]),
				})
			);
		}

		this.store.dispatch(UpdateQuoteData({ data: mapKeyValueArrayToObj(serverRes.policyData) }));
		this.routingService.navigateSuccess.next(serverRes.route as StepsEnum);
	}
}
