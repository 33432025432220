import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionIdKey, SessionService } from '../services/session.service';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
	constructor(private readonly sessionService: SessionService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const sessionId = this.sessionService.getSessionId();

		if (sessionId) {
			const request = req.clone({
				headers: req.headers.set(SessionIdKey, sessionId).set('Source-Type', 'D2C'),
			});

			return next.handle(request);
		}

		return next.handle(req);
	}
}
