import { ActionReducerMap } from '@ngrx/store';
import { IAppState } from '../states/app.state';
import { interviewMetadataReducer } from './interview-metadata.reducer';
import { loaderInformationReducer } from './loader-information.reducer';
import { quoteDataReducer } from './quote-data.reducers';
import { resultDataReducer } from './result-data.reducer';
import { ProgressMeterReducer } from './progress-meter.reducer';
import { activeThemeReducer } from './active-theme.reducer';
import { languageReducer } from './languages.reducer';

export const appReducers: ActionReducerMap<IAppState, any> = {
	quoteData: quoteDataReducer,
	interviewMetadata: interviewMetadataReducer,
	loaderInformation: loaderInformationReducer,
	resultData: resultDataReducer,
	progressMeter: ProgressMeterReducer,
	theme: activeThemeReducer,
	languageSettings: languageReducer,
};
