export enum SourcesEnum {
	MPQ3 = 'MPQ3',
	PROGRESSIVE = 'Progressive.com',
	DQ = 'DQ',
	OLS = 'OLS',
	EMAIL = 'Email',
	AFFILIATES = 'Affiliates',
	MORTGAGE = 'Mortgage',
	BOOK_ROLL = 'BookRoll',
	DMP = 'DMP',
	DMPHQX = 'DMPHQX',
	MPQ3_DR = 'MPQ3_DR',
	MPQ3_RENTERS = 'MPQ3_Renters',
}
