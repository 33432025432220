import { ICoverage } from '../../../entities/result-page.interfaces';
import { CoverageModel } from '../../models/coverage.model';

export interface IResultState {
	seeOtherRates?: boolean;
	rates: CoverageModel[];
	rateSelectedForOfflineBid: CoverageModel[];
	toggledRatesSelected: CoverageModel[];
	coverages?: ICoverage[];
	originTypeOfDwelling: string;
	selectedCarrier: string;
	isQuoteCompleted: boolean;
	isAdditionalQuestionsChosen: boolean;
	isAltRatesVisible: boolean;
	isSecondAdditionalQuestionsChosen: boolean;
	isCurrentInsuranceChosen: boolean;
	isDetailsChosen: boolean;
	isUtilitiesChosen: boolean;
	source?: string;
	Defaults: {
		EffectiveDate: boolean;
		MaritalStatus: boolean;
		PLRoofUpdated: boolean;
		PLNumberOfUnits: boolean;
		IAgreeToReceiveEmailsByBolt;
	};

	[key: string]: any;
}

export const initialResultState: IResultState = {
	seeOtherRates: false,
	rates: [],
	coverages: [],
	selectedCarrier: '',
	originTypeOfDwelling: '',
	isAdditionalQuestionsChosen: false,
	isSecondAdditionalQuestionsChosen: false,
	isCurrentInsuranceChosen: false,
	isAltRatesVisible: false,
	isDetailsChosen: false,
	isUtilitiesChosen: false,
	isQuoteCompleted: false,
	rateSelectedForOfflineBid: [],
	toggledRatesSelected: [],
	Defaults: {
		EffectiveDate: false,
		MaritalStatus: false,
		PLRoofUpdated: false,
		PLNumberOfUnits: false,
		IAgreeToReceiveEmailsByBolt: false,
	},
};
