<div [class.lp-long]="isLpLong" class="app-container source-{{ source }}">
	<ng-container *ngIf="loaderInformation">
		<div *ngIf="loaderInformation.loaderOnStartVisible" class="loaderStart">
			<app-loader></app-loader>
		</div>
		<div *ngIf="loaderInformation.visible" class="overlay"></div>
		<div *ngIf="applicationId" [attr.application_id]="applicationId" class="automation-data"></div>
		<div *ngIf="friendlyId" [attr.friendly_id]="friendlyId" class="automation-data"></div>

		<div *ngIf="!loaderInformation.loaderOnStartVisible" class="page">
			<app-progress-meter
				*ngIf="(progressMeter$ | async).isDefaultProgressVisible"
				[pm]="progressMeter$ | async"
			></app-progress-meter>
			<app-header [pm]="progressMeter$ | async"></app-header>

			<popup-wrapper></popup-wrapper>
			<div
				*ngIf="window.placeApiReady"
				[class.with-footer]="isFooterVisible"
				[ngClass]="[lob]"
				class="app-main-content center"
			>
				<router-outlet></router-outlet>
				<app-footer [langSettings]="langSettings" [theme]="theme"></app-footer>
			</div>
		</div>
	</ng-container>
</div>
