import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LanguagesActions, LoaderActions } from '../actions';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_UI_LANGUAGE } from '../../constants/languages';
import { Store } from '@ngrx/store';

@Injectable()
export class LanguagesEffect {
	setLanguage$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(LanguagesActions.SetLanguage),
				filter((res) => !!res.selected),
				tap((res) => {
					this.store.dispatch(LoaderActions.ShowStartLoader());
					this.translateService.use(res.selected).subscribe(() => {
						this.store.dispatch(LoaderActions.HideStartloader());
					});
				})
			),
		{ dispatch: false }
	);

	setLanguageSettings$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(LanguagesActions.SetLanguageSettings),
				tap((res) => {
					this.translateService.setDefaultLang(res.defaultLanguage || DEFAULT_UI_LANGUAGE);
					this.translateService.use(res.selected || res.defaultLanguage || DEFAULT_UI_LANGUAGE);
				})
			),
		{ dispatch: false }
	);

	constructor(private actions$: Actions, private translateService: TranslateService, private store: Store) {}
}
