import { QuoteDataEffect } from './quote-data.effect';
import { RoutingEffect } from './routing.effect';
import { StoreSessionsStorageEffect } from './store-session-storage.effect';
import { ResultDataEffect } from './result-data.effect';
import { ProgressMeterEffect } from './progress-meter.effect';
import { ApiEffect } from './api.effect';
import { InterviewMetadataEffect } from './interview-metadata.effect';
import { LanguagesEffect } from './languages.effect';

export const effects = [
	InterviewMetadataEffect,
	QuoteDataEffect,
	RoutingEffect,
	StoreSessionsStorageEffect,
	ResultDataEffect,
	ProgressMeterEffect,
	LanguagesEffect,
	ApiEffect,
];
