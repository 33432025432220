import { SourcesEnum } from 'src/app/enums/sources.enum';
import { Dictionary } from 'src/entities/key-value.interface';
import { PrefillIndication } from 'src/entities/prefill-indication.interface';
import { LobsEnum } from '../../enums/lobs.enum';

export interface D2CFeatures {
	oemEndorsement: boolean;
	escrowFlow: {
		carrier: string;
		lob: LobsEnum;
	}[];
	anchorCarriers: {
		[lob: string]: string[];
	};

	[feature: string]: any;
}

export interface IInterviewMetadataState {
	leadSource?: {
		additionalInformation: {
			d2CFeatures: D2CFeatures;
		};
	};
	applicationId?: string;
	FQReportsHandled?: boolean;
	applicantId?: string;
	PolicyId?: string;
	token?: string;
	tokenStatus?: string;
	friendlyId?: string;
	PhoneNumber?: string;
	RatePhoneNumber?: string;
	SourceKeyword?: SourcesEnum;
	LandingStage?: string;
	LdFeatures?: Dictionary<boolean>;
	landingPopupDisplayed?: boolean;
	ssnScriptUrl?: string;
	variables?: any;
	Category?: string;
	SessionId?: string;
	LANID?: string;
	Channel?: string;
	FlowType?: string;
	QuoteState?: string;
	RiskType?: string;
	AddonParams?: string;
	lobSelection?: string[];
	lobsInitialized: string[];
	lobsInApatite: string[];
	originYearBuilt?: string;
	yearBuiltYesNo?: string;
	isRetrieved: boolean;
	BI?: string;

	// pages selection
	// primary Residence Page
	_primaryResidence?: string;
	// properties Page (home flow)
	_typeProperty?: string;
	prevTypeProperty?: string;
	prefillIndication?: PrefillIndication[];

	preBindProcess?: boolean;
	preBindSucceed?: boolean;
	FQSubmission?: boolean;
	Submission?: boolean;
	prefillCall?: boolean;
	isPLRoofUpdatedWasSelected?: boolean;

	isProgressiveSnapshot?: boolean;

	driversOpened: string[];
	vehiclesOpened: string[];
	crossSellTo: string[];
	isMultiLob: boolean;
	isAnonymousFlowEnabled?: boolean;
	isEmailInRetrieval?: boolean;
	isConfirmationEmailSentDisplayed?: boolean;
	isConfirmationEmailQuoteNoteSent?: boolean;
	hasApplicationAppetite?: boolean;
}

export const initialInterviewMetadata: IInterviewMetadataState = {
	PolicyId: '',
	landingPopupDisplayed: false,
	// lobSelection = lob/s user selected and saved by ui (multiLob and cross sell),
	// lobsInitialized = lob/s user selected at first(NO multiLob or cross sell)
	lobSelection: [],
	lobsInitialized: [],
	lobsInApatite: [],
	yearBuiltYesNo: '',
	isRetrieved: false,
	applicationId: '',
	friendlyId: '',
	preBindProcess: false,
	FQSubmission: true,
	Submission: true,
	prefillCall: true,
	isPLRoofUpdatedWasSelected: false,
	isProgressiveSnapshot: false,
	driversOpened: [],
	vehiclesOpened: [],
	crossSellTo: [],
	isMultiLob: false,
	isAnonymousFlowEnabled: false,
	isEmailInRetrieval: false,
	isConfirmationEmailSentDisplayed: false,
	isConfirmationEmailQuoteNoteSent: false,
};
