import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { Store } from '@ngrx/store';
import { combineLatest, first, map, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';

import { IAppState } from './store/states/app.state';
import { ILoaderInformationState } from './store/states/loader-information.state';
import { selectLoaderInformation } from './store/selectors/loader-information.selector';
import { SessionStorageService } from './services/session-storage.service';
import { RoutingService } from './services/routing.service';
import { selectApplicationId, selectFriendlyId } from './store/selectors/interview-metadata.selector';
import { fadeAnimation } from './animations';
import { SessionTimeoutService } from './services/session-timeout.service';
import { selectSource } from './store/selectors/result-data.selectors';
import { MARKETING_NAME_MASK, SOURCE_ID_MASK } from './constants/source-mask-string';
import { selectAddress, selectLobQuoteData } from './store/selectors/quote-data.selectors';
import { ConfigService } from './services/config.service';
import { GeneralPropsEnum } from './models/general-props.enum';
import { selectActiveTheme, selectFooter } from './store/selectors/active-theme.selector';
import { ThemeModel } from './models/theme.model';
import { selectLanguageSettings } from './store/selectors/languages.selector';
import { LanguagesModel } from './models/languages.model';
import { EventActions } from './models/event-data.model';
import { EventsService } from './services/events.service';
import { IProgressMeter } from './store/states/progress-meter.state';
import { selectProgressMeter } from './store/selectors/progress-meter.selector';

@UntilDestroy()
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	animations: [fadeAnimation],
})
export class AppComponent implements OnInit, AfterViewInit {
	loaderInformation: ILoaderInformationState;
	theme: ThemeModel;
	applicationId: string;
	friendlyId: string;
	source: string;
	marketingName: string;
	isAddressEntered: boolean;
	langSettings: LanguagesModel;
	lob = '';
	window: any = window;
	isFooterVisible = false;
	progressMeter$: Observable<IProgressMeter>;
	isLpLong = false;

	constructor(
		private store: Store<IAppState>,
		private sessionStorageService: SessionStorageService,
		public routingService: RoutingService,
		private router: Router,
		private timeoutService: SessionTimeoutService,
		private configService: ConfigService,
		private titleService: Title,
		private eventsService: EventsService,
		private translateService: TranslateService
	) {
		this.initGooglePlacesApi();
		window['gm_authFailure'] = () => {
			this.window.placeApiReady = true;
		};
	}

	ngOnInit() {
		this.sessionStorageService.initStoreSub();
		this.timeoutService.startIdleTimer();

		this.store.select(selectLoaderInformation).subscribe((loaderInformation: ILoaderInformationState) => {
			setTimeout(() => {
				this.loaderInformation = loaderInformation;
			});
		});

		combineLatest([
			this.store.select(selectApplicationId),
			this.store.select(selectFriendlyId),
			this.store.select(selectSource),
			this.store.select(selectAddress),
			this.store.select(selectLobQuoteData),
			this.store.select(selectActiveTheme),
			this.store.select(selectLanguageSettings),
			this.store.select(selectFooter),
		])
			.pipe(untilDestroyed(this))
			.subscribe(([applicationId, friendlyId, source, propertyAddress, lob, theme, langSettings, footer]) => {
				this.applicationId = applicationId || null;
				this.friendlyId = friendlyId || null;
				this.source = source;
				this.theme = theme;
				this.marketingName = theme[GeneralPropsEnum.marketingName];
				this.isAddressEntered = !!propertyAddress.AddressLine1;
				this.langSettings = langSettings;
				this.isFooterVisible = footer?.enabled;
				this.isLpLong = theme.landingPage.filter((s) => s.enabled).length > 1;

				if (lob) {
					this.lob = lob[0];
				}
			});

		this.progressMeter$ = this.store.select(selectProgressMeter);

		this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
			if (event instanceof NavigationEnd) {
				const currentPage = this.router.url.split('?')[0].replace('/', '');
				this.eventsService.addEvent(EventActions.PAGE_VIEW, { path: currentPage.split('/').slice(1).join('/') });

				this.setTitle();

				setTimeout(() => this.countMainContentHeight());
			}
		});

		this.translateService.onLangChange.pipe(untilDestroyed(this)).subscribe(() => {
			this.setTitle();
		});
	}

	ngAfterViewInit(): void {
		this.countMainContentHeight();
	}

	private getActivatedRouteSnapshot() {
		let route = this.router.routerState.root as ActivatedRoute;

		while (route.firstChild) {
			route = route.firstChild;
		}
		return route.snapshot;
	}

	private setTitle() {
		const routeTitle = this.getActivatedRouteSnapshot().data.title;
		const capitalizedSource = this.source ? this.source[0].toUpperCase() + this.source.slice(1) : null;

		setTimeout(() => {
			this.getTitleFromRoute(routeTitle, capitalizedSource)
				.pipe(first())
				.subscribe((translatedTitle) => {
					const title = this.marketingName || translatedTitle || null;
					this.titleService.setTitle(title);
					this.configService.setKey('title', title);
				});
		}, 300);
	}

	private getTitleFromRoute(routeTitle: string, capitalizedSource: string): Observable<string> {
		return this.translateService
			.get(routeTitle)
			.pipe(
				map((title) =>
					title
						.replace(MARKETING_NAME_MASK, this.marketingName || capitalizedSource)
						.replace(SOURCE_ID_MASK, capitalizedSource)
				)
			);
	}

	private countMainContentHeight(): void {

		const appHeight = () => {
			// count main header height for mobile so the height won't count browsers navigation height
			const appHeader = document.querySelector('app-header');
			const appProgressMeter = document.querySelector('app-progress-meter');
			const headerHeight = (appHeader?.scrollHeight || 0) + (appProgressMeter?.scrollHeight || 0);

			const doc = document.documentElement;
			const height = window.innerHeight - headerHeight;
			doc.style.setProperty('--main-content-height', `${height}px`);
			doc.style.setProperty('--real-device-height', `${window.innerHeight}px`);
		};

		window.addEventListener('resize', appHeight);

		appHeight();
	}

	private initGooglePlacesApi(): void {
		const script = document.createElement('script');
		script.src = `https://maps.googleapis.com/maps/api/js?key=${this.configService.getConfig().placesApiKey
			}&libraries=places&callback=finishLoadingPlacesApi`;
		script.async = true;
		script.defer = true;
		document.body.appendChild(script);
	}
}
