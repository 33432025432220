import { createReducer, on } from '@ngrx/store';

import { SetLanguage, SetLanguageSettings } from '../actions/languages.actions';
import { initialLanguagesState } from '../states/languages.state';

const reducer = createReducer(
	initialLanguagesState,
	on(SetLanguage, (state, { selected }) => ({ ...state, selected })),
	on(SetLanguageSettings, (state, { list, defaultLanguage, selected }) => ({
		...state,
		list,
		defaultLanguage,
		selected,
	}))
);

export function languageReducer(state, action) {
	return reducer(state, action);
}
