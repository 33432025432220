import { BnNgIdleService } from 'bn-ng-idle';
import { ActionApiService } from './action-api.service';
import { ProgressMeterService } from './progress-meter.service';
import { RoutingService } from './routing.service';
import { SessionStorageService } from './session-storage.service';
import { SessionTimeoutService } from './session-timeout.service';
import { BuyCoverageButtonService } from './buy-coverage-button.service';
import { PrefillDefaultsService } from './prefill-defaults.service';
import { SourceFeaturesService } from './source-features.service';

export const servicesArr = [
	ActionApiService,
	RoutingService,
	SessionStorageService,
	ProgressMeterService,
	BnNgIdleService,
	SessionTimeoutService,
	BuyCoverageButtonService,
	PrefillDefaultsService,
	SourceFeaturesService
];
