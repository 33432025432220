import { createReducer, on } from '@ngrx/store';
import {
	ActivateStep,
	resetProgressBar,
	setAllAsNotVisited,
	setFinalStep,
	setFlowType,
	UpdateProgressMeter,
} from '../actions/progress-meter.actions';
import { initialProgressMeter, IProgressMeter } from '../states/progress-meter.state';
import { StepsEnum } from '../../enums/steps.enum';
import { LobsEnum } from '../../enums/lobs.enum';
import { FlowType } from '../../enums/step-groups.enum';
import { isMobile } from 'src/app/utils/general.utils';
import { getIsMultiLob, groupedSteps } from '../../utils/pm-step-groups';
import { IStep } from 'src/app/models/step-groups.model';

const reducer = createReducer(
	initialProgressMeter,
	on(UpdateProgressMeter, (state, { data }) => ({ ...state, ...data })),
	on(ActivateStep, (state, { stepName }) => {
		const newState: IProgressMeter = JSON.parse(JSON.stringify(state));
		const stepIndex: number = newState.flowOptions[newState.flowType].findIndex(
			(step: IStep) => step.name === stepName
		);

		if (stepIndex > -1) {
			const step = newState.flowOptions[newState.flowType][stepIndex];

			newState.activeStep = stepIndex;
			newState.flowOptions[newState.flowType][stepIndex].visited = true;
			newState.groupedSteps = groupedSteps(newState);
			newState.isMultiLob = getIsMultiLob();
			newState.isStepsProgressVisible = step?.stepProperties?.progressMeter?.visible && !isMobile();
			newState.isDefaultProgressVisible =
				!step?.stepProperties?.progressMeter?.visible &&
				step?.stepProperties?.progressMeter?.flowType !== FlowType.fq &&
				![StepsEnum.LOB_SELECTION, StepsEnum.YOUR_ADDRESS, StepsEnum.CONFIRMATION_EMAIL_SENT].includes(
					step?.name as StepsEnum
				) &&
				(newState.flowType === null ||
					newState.flowType === LobsEnum.RENTERS ||
					newState.flowType === LobsEnum.PETS ||
					newState.flowType === LobsEnum.CONDOMINIUM) &&
				!step?.name?.includes('error') &&
				!isMobile();
		} else {
			/*
			 * Hide both PMs if the active step out of Flows
			 * such as Error pages
			 * */
			newState.isStepsProgressVisible = false;
			newState.isDefaultProgressVisible = false;
		}

		return { ...newState };
	}),
	on(setFinalStep, (state, { }) => {
		const newState: IProgressMeter = JSON.parse(JSON.stringify(state));
		newState.activeStep = newState.flowOptions[newState.flowType].length - 1;
		return { ...newState };
	}),
	on(resetProgressBar, (state) => {
		return { ...initialProgressMeter };
	}),
	on(setFlowType, (state, { flowType }) => {
		return { ...state, flowType };
	}),
	on(setAllAsNotVisited, (state) => {
		const newState = JSON.parse(JSON.stringify(state));
		newState.flowOptions[newState.flowType].forEach((item) => {
			return item;
		});
		for (let i = 1; i < newState.flowOptions[newState.flowType].length - 1; i++) {
			newState.flowOptions[newState.flowType][i].visited = false;
		}
		return { ...initialProgressMeter };
	})
);

export function ProgressMeterReducer(state, action) {
	return reducer(state, action);
}
