import { Action, createReducer, on } from '@ngrx/store';
import { SetAlternativeLogo, UpdateActiveTheme } from '../actions/theme.action';
import { GeneralPropsEnum } from '../../models/general-props.enum';
import { initialActiveTheme } from '../states/theme.state';
import { ThemeModel } from '../../models/theme.model';
import { LandingSectionModel } from '../../models/landing-section.model';

const mergeLPSections = (stateSections: LandingSectionModel[], newSections: LandingSectionModel[]) => {
	if (newSections?.length) {
		return newSections;
	}

	return stateSections;
};

const reducer = createReducer(
	initialActiveTheme,
	on(UpdateActiveTheme, (state, { theme }) => {
		const themeTexts = {};
		const steps = [...new Set([...Object.keys(state.texts), ...Object.keys(theme.texts)])];

		steps.forEach((step) => {
			themeTexts[step] = {
				...state.texts[step],
				...theme.texts[step],
			};
		});

		return {
			...state,
			...theme,
			[GeneralPropsEnum.properties]: { ...state.properties, ...theme.properties },
			[GeneralPropsEnum.fonts]: { ...state.fonts, ...theme.fonts },
			[GeneralPropsEnum.additionalClasses]: { ...state.additionalClasses, ...theme.additionalClasses },
			[GeneralPropsEnum.texts]: themeTexts,
			[GeneralPropsEnum.images]: { ...state.images, ...theme.images },
			[GeneralPropsEnum.landingPage]: [...mergeLPSections(state.landingPage, theme.landingPage)],
		} as ThemeModel;
	}),
	on(SetAlternativeLogo, (state, { logo }) => ({
		...state,
		localSettings: { ...state.localSettings, altLogo: logo },
	}))
);

export function activeThemeReducer(state: ThemeModel | undefined, action: Action) {
	return reducer(state, action);
}
