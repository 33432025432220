import { createReducer, on } from '@ngrx/store';
import {
	addDriversOpened,
	addVehiclesOpened,
	removeCrossSellTo,
	ResetInterviewMetadata,
	SetFQReportsHandled,
	UpdateCrossSellTo,
	UpdateInterviewMetadata,
	UpdateLobsInitialized,
	UpdateOriginYearBuilt,
	UpdatePrefillIndication,
} from '../actions/interview-metadata.actions';
import { initialInterviewMetadata } from '../states/interview-metadata.state';
import { updateApplicantId } from '../actions/api.actions';

const reducer = createReducer(
	initialInterviewMetadata,
	on(UpdateInterviewMetadata, (state, { data }) => ({ ...state, ...data })),
	on(UpdateOriginYearBuilt, (state, { data }) => {
		return { ...state, originYearBuilt: data };
	}),
	on(ResetInterviewMetadata, (state) => ({
		...state,
		...initialInterviewMetadata,
		lobsInApatite: state.lobsInApatite,
	})),
	on(UpdatePrefillIndication, (state, { data }) => {
		return {
			...state,
			prefillIndication: data,
		};
	}),

	on(UpdateLobsInitialized, (state, { lobsInitialized }) => {
		return { ...state, lobsInitialized: [...lobsInitialized] };
	}),
	on(UpdateCrossSellTo, (state, { crossSellTo }) => {
		// adds crossSellTo array item only if it is not already present
		return {
			...state,
			crossSellTo: [...state.crossSellTo.filter((item) => !crossSellTo.includes(item)).concat(crossSellTo)],
		};
	}),
	on(removeCrossSellTo, (state, { crossSellTo }) => {
		// adds crossSellTo array item only if it is not already present
		return { ...state, crossSellTo: [...state.crossSellTo.filter((item) => !crossSellTo.includes(item))] };
	}),
	on(addDriversOpened, (state, { Id }) => {
		if (!state.driversOpened.includes(Id)) {
			return { ...state, driversOpened: [...state.driversOpened, Id] };
		}
		return state;
	}),
	on(addVehiclesOpened, (state, { Id }) => {
		if (state.vehiclesOpened && !state.vehiclesOpened.includes(Id)) {
			return { ...state, vehiclesOpened: [...state.vehiclesOpened, Id] };
		}
		return state;
	}),
	on(updateApplicantId, (state, { applicantId }) => ({
		...state,
		applicantId,
	})),
	on(SetFQReportsHandled, (state, { data }) => ({
		...state,
		FQReportsHandled: data,
	}))
);

export function interviewMetadataReducer(state, action) {
	return reducer(state, action);
}
