import { enableProdMode } from '@angular/core';

import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { platformBrowserDynamicTesting } from '@angular/platform-browser-dynamic/testing';
import { AppModule } from './app/app.module';

// if the url is boltinsurance, enable production mode
const baseUrl = window.location.href;
if (baseUrl.includes("boltinsurance")) {
	enableProdMode();
}

// bootstrapApplication(AppComponent, appConfig)
// 	.catch(err => console.error(err));

platformBrowserDynamicTesting().bootstrapModule(AppModule)
	.catch(err => console.error(err));
