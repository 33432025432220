import { createReducer, on } from '@ngrx/store';
import { initialResultState } from '../states/result-data.state';
import {
	addToggledRatesSelected,
	carrierSelectedForOfflineBid,
	carrierSelectSubmitted,
	clearToggledRatesSelected,
	paymentPlanSelect,
	removeToggledRatesSelected,
	ResetResultData,
	setIsAdditionalQuestionsChosen,
	setIsDetailsChosen,
	setIsQuoteCompleted,
	setIsSecondAdditionalQuestionsChosen,
	setIsUtilitiesChosen,
	setOriginTypeOfDwelling,
	setPaymentData,
	setResultData,
	setSecretSource,
	setSource,
	showAltRates,
	singleApplicationSubmitted,
	toggleOtherRates,
} from '../actions/result-data.actions';

const reducer = createReducer(
	initialResultState,
	on(setResultData, (state, { data }) => ({ ...state, ...data })),
	on(toggleOtherRates, (state) => ({ ...state, seeOtherRates: !state.seeOtherRates })),
	on(singleApplicationSubmitted, (state) => ({ ...state, submittedSingleApplication: true })),
	on(carrierSelectSubmitted, (state, { carrier }) => {
		return {
			...state,
			selectedCarrier: carrier,
		};
	}),
	// TODO: why we need separate reducer & property for offline (we can't know the carrier selected)
	on(carrierSelectedForOfflineBid, (state, { rates }) => {
		// why we sending empty array on init
		if (rates?.length === 0) {
			return {
				...state,
				rateSelectedForOfflineBid: [],
			};
		}
		return {
			...state,
			rateSelectedForOfflineBid: rates,
			selectedCarrier: rates[0].carrier,
		};
	}),

	on(paymentPlanSelect, (state, { selectedPaymentPlan }) => {
		const rates = JSON.parse(JSON.stringify(state.rates));
		if (!rates) {
			console.error("can't select payment plan if has no rates");
			return {
				...state,
			};
		}

		const selectedCarrier = Object.values(rates).filter((rate) => rate['selected']);
		const index = Object.values(rates).findIndex((rate) => rate['selected']);

		if (!selectedCarrier || !selectedCarrier[0]) {
			console.error("can't select payment plan if no carrier rate has been selected");
			return {
				...state,
			};
		}

		selectedCarrier[0]['paymentPlans'].forEach((plan) => {
			if (plan.id === selectedPaymentPlan.id) {
				plan.selected ? (plan.selected = false) : (plan.selected = true);
			} else {
				plan.selected = false;
			}
		});

		rates[index].paymentPlans = selectedCarrier[0]['paymentPlans'];

		return {
			...state,
			rates: [...rates],
		};
	}),

	on(setPaymentData, (state, data) => ({ ...state, paymentData: data })),
	on(setOriginTypeOfDwelling, (state, data) => {
		return {
			...state,
			originTypeOfDwelling: data.OriginTypeOfDwelling,
		};
	}),
	on(setIsAdditionalQuestionsChosen, (state) => ({
		...state,
		isAdditionalQuestionsChosen: true,
	})),
	on(showAltRates, (state, data) => ({
		...state,
		isAltRatesVisible: data.show,
	})),
	on(setIsSecondAdditionalQuestionsChosen, (state) => ({
		...state,
		isSecondAdditionalQuestionsChosen: true,
	})),
	on(setIsDetailsChosen, (state) => ({
		...state,
		isDetailsChosen: true,
	})),
	on(setIsUtilitiesChosen, (state) => ({
		...state,
		isUtilitiesChosen: true,
	})),
	on(setSource, (state, { data }) => ({ ...state, source: data })),
	on(setSecretSource, (state, { data }) => ({ ...state, secretSource: data })),
	on(setIsQuoteCompleted, (state, { data }) => ({ ...state, isQuoteCompleted: data })),

	on(ResetResultData, (state) => ({ ...initialResultState, source: state.source })),

	on(clearToggledRatesSelected, (state) => ({ ...state, toggledRatesSelected: [] })),
	on(addToggledRatesSelected, (state, { rates }) => {
		const toggledRatesSelected = [...state.toggledRatesSelected] || [];
		if (rates && rates.length) {
			rates.forEach((rate) => {
				const rateIndex = toggledRatesSelected.findIndex((toggledRate) => rate.id === toggledRate.id);
				if (rateIndex !== -1) {
					toggledRatesSelected[rateIndex] = rate;
				} else {
					toggledRatesSelected.push(rate);
				}
			});
		}
		return { ...state, toggledRatesSelected };
	}),

	on(removeToggledRatesSelected, (state, { rates }) => {
		const toggledRatesSelected = [...state.toggledRatesSelected] || [];
		if (rates && rates.length) {
			rates.forEach((rate) => {
				const rateIndex = toggledRatesSelected.findIndex((toggledRate) => rate.id === toggledRate.id);
				if (rateIndex !== -1) {
					toggledRatesSelected.splice(rateIndex, 1);
				}
			});
		}
		return { ...state, toggledRatesSelected };
	})
);

export function resultDataReducer(state, action) {
	return reducer(state, action);
}
