import { CurrencyPipe } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routes } from './routing/routing';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { appReducers } from './store/reducers/app.reducers';
import { effects } from './store/effects';
import { servicesArr } from './services/services';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ActionApiService } from './services/action-api.service';
import { NgOnDestroyService } from './services/ng-on-destroy.service';
import { AppComponent } from './app.component';
import { metaReducers } from './store/meta-reducers';
import { ConfigService, IConfig } from './services/config.service';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { AppService } from './services/app.service';
import { RetrieveQuoteService } from './services/retrieve-quote.service';
import { EventsService } from './services/events.service';
import { LanguageService } from './services/language.service';
import { SessionInterceptor } from './interceptors/session.interceptor';
import { getD2CFeatures } from './utils/general.utils';
import { SharedModule } from './modules/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
	declarations: [
		AppComponent,
	],
	bootstrap: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		SharedModule,
		EffectsModule.forRoot([...effects]),
		StoreModule.forRoot(appReducers, { metaReducers }),
		StoreDevtoolsModule.instrument({ connectInZone: true }),
		RouterModule.forRoot(routes),
	], providers: [
		// TODO remove currency pipe from this file
		CurrencyPipe,
		NgOnDestroyService,
		{
			provide: APP_INITIALIZER,
			useFactory: (_http: HttpClient, configService: ConfigService, actionApiService: ActionApiService, retrieveQuoteService: RetrieveQuoteService, appService: AppService, eventsService: EventsService, languageService: LanguageService) => {
				return (): Promise<any> => {
					return new Promise<void>((resolve) => {
						appService.trySetBaseOnStartup();
						_http.get('./environment.json').subscribe((config: IConfig) => {
							configService.config = config;
							sessionStorage.setItem('environment', JSON.stringify(config));
							actionApiService.initTenant().subscribe((source) => {
								languageService.initLanguage();
								appService.generateStyles(actionApiService.AlternateSource(source)).subscribe(() => {
									if (!source) {
										resolve();
										return;
									}
									actionApiService
										.validateSource(source)
										.then((res) => {
											retrieveQuoteService.extractToken(source).subscribe(() => {
												eventsService.init(getD2CFeatures()?.mixpanelToken);
												resolve();
											});
										})
										.catch(() => {
											resolve();
										});
								});
							});
						});
					});
				};
			},
			deps: [
				HttpClient,
				ConfigService,
				ActionApiService,
				RetrieveQuoteService,
				AppService,
				EventsService,
				LanguageService,
			],
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpErrorInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: SessionInterceptor,
			multi: true,
		},
		servicesArr,
		provideHttpClient(withInterceptorsFromDi()),
	]
})
export class AppModule { }
