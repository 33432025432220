import { Actions, createEffect, ofType } from '@ngrx/effects';

import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class InterviewMetadataEffect {
  constructor(private actions$: Actions) { }

}
