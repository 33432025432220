import { createReducer, on } from '@ngrx/store';
import { Hideloader, ShowLoader, ShowStartLoader, HideStartloader } from '../actions/loader.actions';

import { initialLoaderInformationState } from '../states/loader-information.state';

const showLoader = (state) => ({ ...state, visible: true});
const hideLoader = (state) => ({ ...state, visible: false });
const showStartLoader = (state) => ({ ...state, loaderOnStartVisible: true});
const hideStartLoader = (state) => ({ ...state, loaderOnStartVisible: false });


const reducer = createReducer(
	initialLoaderInformationState,
	on(ShowLoader, showLoader),
	on(Hideloader, hideLoader),
	on(ShowStartLoader, showStartLoader),
	on(HideStartloader, hideStartLoader),
);

export function loaderInformationReducer(state, action) {
	return reducer(state, action);
}
