import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UpdateQuoteData, UpdateQuoteDataFinished } from '../actions/quote-data.actions';
import { switchMap } from 'rxjs/operators';
import { IAppState } from '../states/app.state';
import { Injectable } from '@angular/core';
import { getStoreSessionsStorage } from '../actions/store-session-storage.actions';
import { UpdateInterviewMetadata } from '../actions/interview-metadata.actions';
import { UpdateProgressMeter } from '../actions/progress-meter.actions';
import { setResultData } from '../actions/result-data.actions';

@Injectable()
export class StoreSessionsStorageEffect {
	constructor(private actions$: Actions) { }

	getStoreSessionsStorage$ = createEffect(() =>
		this.actions$.pipe(
			ofType(getStoreSessionsStorage),
			switchMap((res: IAppState) => [
				UpdateQuoteData({ data: res.quoteData }),
				// UpdateProgressMeter({ data: res.progressMeter }),//TODO: migrate this
				UpdateInterviewMetadata({ data: res.interviewMetadata }),
				setResultData({ data: res.resultData }),
				UpdateQuoteDataFinished(),
			])
		)
	);
}
